// MainPage/MainPage.js
import React from 'react';
import './mainpage.css'; // Update the filename here
import logo from '../../assets/codehidelogo1.png';

function MainPage() {
  return (
    <div className="main-page">
      <h1 className="heading">Gym Fighters Coming Soon</h1>
      <div className="content">
        <p>We are working hard to bring you an amazing experience!</p>
        <p>Stay tuned for updates.</p>
        <div className="info">
          <h2>About Us</h2>
          <p>At Gym Fighters, we are dedicated to providing a comprehensive fitness experience tailored to our members' goals and aspirations. Our state-of-the-art facility offers a range of amenities and services, including:</p>
          <ul>
            <li>Personal Training, with certified instructors to guide and motivate you towards achieving your fitness objectives.</li>
            <li>Group Fitness Classes, featuring a variety of workouts such as HIIT, CrossFit, and yoga, suitable for all fitness levels.</li>
            <li>Strength and Conditioning Equipment, including free weights, machines, and functional training tools to support your strength and muscle-building goals.</li>
            <li>Martial Arts Training, offering classes in disciplines such as boxing, MMA, Brazilian Jiu-Jitsu, and Muay Thai for those interested in combat sports.</li>
          </ul>
          <p>Our commitment is to foster a supportive community where individuals can challenge themselves, surpass their limits, and transform their lives through fitness. Whether you're a seasoned athlete or new to the gym, Gym Fighters is your ultimate destination for health, wellness, and empowerment.</p>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
